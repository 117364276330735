import BezierEasing from "./bezier-easing";
const timingFunctions = new Map();

const easeTimeFn = BezierEasing(0.25, 0, 0.25, 1);

timingFunctions
	.set("linear", (n) => {
		return n;
	})
	.set("ease", (n) => {
		return easeTimeFn(n);
	})
	.set("ease-in", (n) => {
		return Math.pow(n, 1.675);
	})
	.set("ease-out", (n) => {
		return 1 - Math.pow(1 - n, 1.675);
	})
	.set("ease-in-out", (n) => {
		return 0.5 * (Math.sin((n - 0.5) * Math.PI) + 1);
	});

// return promise, so that user can take after action
function animate(duration, timingFn, draw) {
	duration = duration || 250;
	timingFn = timingFn || timingFunctions.get["ease"];

	const start = performance.now();
	return new Promise((resolve) => {
		requestAnimationFrame(function animate(time) {
			let timeFraction = (time - start) / duration;
			timeFraction > 1 && (timeFraction = 1);
			draw(timingFn(timeFraction), timeFraction);
			timeFraction < 1 ? requestAnimationFrame(animate) : resolve(true);
		});
	});
}

function offCanvasOpen(card, position) {
	const body = document.querySelector("body");
	const cardStyle = getComputedStyle(card);
	const marginTB = parseInt(cardStyle.marginTop) + parseInt(cardStyle.marginBottom);
	const marginLR = parseInt(cardStyle.marginLeft) + parseInt(cardStyle.marginRight);
	const modalHeight = card.offsetHeight + marginTB;
	const modalWidth = card.offsetWidth + marginLR;
	const duration = parseFloat(cardStyle.getPropertyValue("--bm-transition-duration")) * 1000;
	const cardTimingFn = cardStyle.getPropertyValue("--bm-transition-function").trim();
	const modalTimingFn = timingFunctions.get(cardTimingFn);

	let drawFn = null;
	// The draw function :D
	switch (position) {
		case "top":
			drawFn = (pct) => {
				body.style.top = modalHeight * pct + "px";
			};
			break;
		case "left":
			drawFn = (pct) => {
				body.style.left = modalWidth * pct + "px";
			};
			break;
		case "right":
			drawFn = (pct) => {
				//body.style.right = modalWidth * pct + "px";
			};
			break;
		case "bottom":
			drawFn = (pct) => {
				body.style.bottom = modalHeight * pct + "px";
			};
			break;
		default:
			throw {
				message: `Better Modal: Unsupported position "${position}" found on this modal in combination with offcanvas functionality`,
				el: card.closest("[data-better-modal]"),
			};
	}

	animate(duration, modalTimingFn, drawFn);
}

function offCanvasClose(card, position) {
	const body = document.querySelector("body");
	const cardStyle = getComputedStyle(card);
	const marginTB = parseInt(cardStyle.marginTop) + parseInt(cardStyle.marginBottom);
	const marginLR = parseInt(cardStyle.marginLeft) + parseInt(cardStyle.marginRight);
	const modalHeight = card.offsetHeight + marginTB;
	const modalWidth = card.offsetWidth + marginLR;
	const duration = parseFloat(cardStyle.getPropertyValue("--bm-transition-duration")) * 1000;
	const cardTimingFn = cardStyle.getPropertyValue("--bm-transition-function").trim();
	const modalTimingFn = timingFunctions.get(cardTimingFn);

	let drawFn = null;
	// The draw function :D
	switch (position) {
		case "top":
			drawFn = (pct) => {
				body.style.top = modalHeight - modalHeight * pct + "px";
			};
			break;
		case "left":
			drawFn = (pct) => {
				body.style.left = modalWidth - modalWidth * pct + "px";
			};
			break;
		case "right":
			drawFn = (pct) => {
				//body.style.right = modalWidth - modalWidth * pct + "px";
			};
			break;
		case "bottom":
			drawFn = (pct) => {
				body.style.bottom = modalHeight - modalHeight * pct + "px";
			};
			break;
		default:
			throw {
				message: `Better Modal: Unsupported position "${position}" found on this modal in combination with offcanvas functionality`,
				el: card.closest("[data-better-modal]"),
			};
	}

	animate(duration, modalTimingFn, drawFn);
}

export { offCanvasOpen, offCanvasClose };
